import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import Button from "../../../components/NewButtonSubmit";
const AllScope = (props) => {
    let {scope} = props;
    const [brand, setBrand] = useState([scope]);
    let history = useHistory();
    let handleRoute = (slug) => {
        history.push(`/${slug}`);

    };

    useEffect(() => {
        const fetchScopeOfWork = async () => {
            try {
                const response = await fetch("https://api.shantalifestyle.com/wp-json/scope/all"); // Replace with actual API
                const result = await response.json();
                setBrand(result); // Assuming result is an array
                console.log('ok scrop loading')
            } catch (err) {
            } finally {
            }
        };
        fetchScopeOfWork();


    }, []);
    return (
        <StyledAllScope product={brand}>
            {brand ? (
                <Container>
                    <Row className="change-view">
                        <Col>
                            <div
                                style={{display: "flex", marginTop: 90, alignItems: "center"}}
                            >
                                <div className="text-breadcrumb">Shanta LifeStyle</div>
                                <div className="text-breadcrumb-point"/>
                                <div className="text-breadcrumb">Scope of Work</div>
                            </div>
                            <Divider style={{marginTop: 6, marginBottom: 30}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="subTitle">SOW</div>
                        </Col>
                    </Row>
                    <Row className="title-row">
                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div className="title">Scope of Work</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {brand && brand.length > 0 ? brand.map((item, key) => {
                                return (
                                    <Col key={key} lg={3} md={6} sm={6} xs={6} className="column-cl">
                                        <div
                                            key={item.term_id}
                                            className="client-single"
                                            onClick={() => handleRoute('book-appointment')}
                                        >
                                            <div className="client-single__inner">
                                                <img src={item.full_path} alt=""/>
                                            </div>
                                            <div className="background">
                                                <div className="middle-data">
                                                    <div className="middle-data-inner">
                                                        {/* <img src={brand} alt=""/> */}
                                                        <div className="brand-name">
                                                            {ReactHtmlParser(item.name)}
                                                        </div>

                                                        <div className="link">
                                                            <Button
                                                                text={"Book An Appointment"}
                                                                onClick={() => handleRoute('book-appointment')}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })
                            : null}
                    </Row>
                </Container>
            ) : (
                <div className="loader">
                    <CircularProgress/>
                </div>
            )}
        </StyledAllScope>
    );
};

const StyledAllScope = styled.div`
    padding-bottom: 64px;
    padding-top: 25px;
    height: ${(props) => (props.product ? "auto" : "120vh")};
    background: #eae9e9;

    .loader {
        margin-top: 25%;
        text-align: center;
    }

    .container {
        max-width: 95%;
        @media (max-width: 991px) {
            max-width: 100%;
            padding: 20px;
        }
    }

    .title-row {
        margin-bottom: 40px;
    }

    .title-col {
        padding: 0;
    }

    .text-breadcrumb {
        font-weight: normal;
        font-size: 10px;
        color: #313132;
        font-family: "Poppins", sans-serif;
    }

    .text-breadcrumb-point {
        width: 3px;
        height: 3px;
        color: #313132;
        border-radius: 50%;
        background-color: #313132;
        margin-right: 8px;
        margin-left: 8px;
    }

    .change-view {
        display: block;
        @media (max-width: 991px) {
            display: none;
        }
    }

    .subTitle {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding-bottom: 2px;
        margin-top: 10px;
        color: rgb(156, 148, 52);
        @media (max-width: 991px) {
            margin-top: 120px;
        }
    }

    .title {
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        color: #313132;
        margin-bottom: 24px;
        @media (max-width: 991px) {
            margin-bottom: 15px;
        }
    }

    .column-cl {
        margin-bottom: 30px;

        .container {
            max-width: 100%;
        }
    }

    .client-single {
        padding: 20px;
        background: #fff;
        border-radius: 30px;
        text-align: center;
        &__inner {
            padding-top: 74.07%;
            border: 0 solid #707070;
            cursor: pointer;
            border-radius: 30px;
            overflow: hidden;
            position: relative;
            
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                bottom: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

        }

        .brand-name {
            color: #000;
            text-transform: capitalize;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            text-align: center;
            margin: 15px auto 10px;
            min-height: 70px;
        }

        .link {
            cursor: pointer;
            color: #282c34;
            display: inline-block;
            margin: 0;
            button{
                margin: 0;
            }
            .custom-bg-button{
                padding-left: 10px;
                padding-right: 10px;
                margin: 0;
            }
            &:hover {
                color: rgb(156, 148, 52);
            }
        }


        &:hover {
            .link {
                color: rgb(156, 148, 52);
            }
        }

    }

    @media(max-width: 767px){
        .column-cl{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    
    

`;

function mapStateToProps(state) {
    console.log("Redux state:", state?.homeReducer); // Debugging line
    return {
        scope: state.homeReducer.scope,
    };
}

export default connect(mapStateToProps)(AllScope);
