import React, {useEffect, useState} from "react";
import {PageAnimation} from "../../components/PageAnimation";
import styled from "styled-components";
import AllProduct from "./components/all-brand-products";
import {useParams} from "react-router-dom";
import {motion} from "framer-motion";
import {
    fetchCategoryProducts,
    fetchFeaturedProducts,
    fetchFiltered,
    filterInit,
    categoryProductInit, fetchCategoryProductsWithPost, fetchBrand,

} from "../../redux/actions/home";
import {connect} from 'react-redux'
import CircularProgress from "@material-ui/core/CircularProgress";

const BrandProductList = (props) => {
    let {
        categories,
        allProducts,
        posts,
        fetchFiltered,
        filteredProduct,
        fetchCategoryProducts,
        categoryProducts,
        filterInit,
        categoryProductInit,
        categoryProductsLoading,
        fetchCategoryProductsWithPost,
        fetchBrandList,
        submit
    } = props;
    console.log(

        categoryProductInit
    )
    const {slug} = useParams();

    let [isSearch, setSearch] = useState(false)
    let [data, setData] = useState(
        {
            category_id: null,
            colors: [],
            height: [],
            material: [],
            pageNum: 1,
            post_per_page: '20',
            price: [],
            sort: [],
            width: []
        }
    )

    let brandId = null;

    useEffect(() => {
        window.scrollTo(0, 0);
        categoryProductInit()
    }, [slug])

    const brand = categories
        ? categories.length
            ? categories.find((item) => item.slug === slug)
            : null
        : null;

    brandId = brand ? brand.term_id : null
    const [pageNum, setPageNumber] = useState(1);
    useEffect(() => {
        if (brandId) {
            if (!isSearch) {
                fetchCategoryProductsWithPost({id: brandId, pageNum: pageNum, post_per_page: "20"})
            } else {
                data.category_id = brandId
                data.pageNum = pageNum
                props.fetchCategoryProductsWithPost({
                    id: brandId,
                    pageNum: pageNum,
                    post_per_page: "20"
                }, [], true, data)
            }
        }

    }, [brandId]);


    console.log(categoryProductsLoading,'load')
    return (
        <motion.div
            exit="exit"
            animate="show"
            variants={PageAnimation}
            initial="hidden"
        >
            <StyledBrand product={!categoryProductsLoading}>
                {!categoryProductsLoading ? (
                    <AllProduct
                        slug={slug}
                        pageNumber={pageNum}
                        changePageNumber={setPageNumber}
                        categories={categories}
                        allProducts={allProducts}
                        posts={posts}
                        fetchFiltered={fetchFiltered}
                        filteredProduct={filteredProduct}
                        fetchCategoryProducts={fetchCategoryProductsWithPost}
                        categoryProducts={categoryProducts}
                        filterInit={filterInit}
                        catId={brandId}
                        id={brandId}
                        brand={brand}

                        categoryProductInit={categoryProductInit}
                        setData={setData}
                        data={data}
                        setSearch={setSearch}
                        isSearch={isSearch}
                        submit={submit}
                    />
                ) : (
                    <div className="loader">
                        <CircularProgress/>
                    </div>
                )}
            </StyledBrand>
        </motion.div>
    );
};

function mapStateToProps(state) {
    console.log(state.homeReducer, 'this sis')
    return {
        categories: state.homeReducer.categories,
        brandList: state.homeReducer.brandList,
        featuredProducts: state.homeReducer.featuredProducts,
        allProducts: state.homeReducer.allProducts,
        posts: state.homeReducer.posts,
        filteredProduct: state.homeReducer.filteredProduct,
        categoryProducts: state.homeReducer.categoryProducts,
        categoryProductsLoading: state.homeReducer.categoryProductsLoading,
        submit: state.prebookReducer.submit,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchBrandList: () => dispatch(fetchBrand()),
        fetchFeatured: (data) => dispatch(fetchFeaturedProducts(data)),
        fetchFiltered: (data) => dispatch(fetchFiltered(data)),
        fetchCategoryProducts: data => dispatch(fetchCategoryProducts(data)),
        fetchCategoryProductsWithPost: (data, prev_data_list, isSearch, search) => dispatch(fetchCategoryProductsWithPost(data, prev_data_list, isSearch, search)),
        filterInit: () => dispatch(filterInit()),
        categoryProductInit: () => dispatch(categoryProductInit())
    };
}

const StyledBrand = styled.div`
  padding-bottom: 64px;
  height: auto;
  min-height: 120vh;

  .loader {
    margin-top: 25%;
    text-align: center;

    @media (max-width: 991px) {
      margin-top: 40%;
    }
  }

  @media (max-width: 991px) {
    margin-top: 100px;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(BrandProductList);
