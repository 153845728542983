import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";

const AllBrand = (props) => {
  let { categories } = props;
  
  let history = useHistory();

  let handleRoute = (slug) => {
    history.push(`/brand-products/${slug}`);
  };

  let brand = categories
    ? categories.length
      ? categories.find((item) => item.slug === "brand")
      : null
    : null;
  
  let brandId = brand ? brand.term_id : null;

  let brandChild = brandId
    ? categories.length
      ? categories.filter((item) => item.parent === brandId)
      : []
    : [];
  return (
    <StyledAllBrand product={brandId}>
      {brandId ? (
        <Container>
          <Row className="change-view">
            <Col>
              <div
                style={{ display: "flex", marginTop: 90, alignItems: "center" }}
              >
                <div className="text-breadcrumb">Category</div>
                <div className="text-breadcrumb-point" />
                <div className="text-breadcrumb">Brands</div>
                <div className="text-breadcrumb-point" />
                <div className="text-breadcrumb">Bedroom</div>
              </div>
              <Divider style={{ marginTop: 6, marginBottom: 30 }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="subTitle">Products</div>
            </Col>
          </Row>
          <Row className="title-row">
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="title">Shop by Brand</div>
              </div>
            </Col>
          </Row>

          <Row>
            {brandChild.length
              ? brandChild.map((item, key) => {
                  return (
                    <Col key={key} lg={3} md={6} sm={6} xs={6} className="column-cl">
                      <div
                        key={item.term_id}
                        className="client-single"
                        onClick={() => handleRoute(item.slug)}
                      >
                        <div className="client-single__inner">
                          <img src={item.image_url} alt="" />
                          <div className="background">
                            <div className="middle-data">
                              <div className="middle-data-inner">
                                {/* <img src={brand} alt=""/> */}
                                <div className="brand-name">
                                  {ReactHtmlParser(item.name)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
              : null}
          </Row>
        </Container>
      ) : (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </StyledAllBrand>
  );
};

const StyledAllBrand = styled.div`
  padding-bottom: 64px;
  height: ${(props) => (props.product ? "auto" : "120vh")};

  .loader {
    margin-top: 25%;
    text-align: center;
  }

  .container {
    max-width: 95%;
    @media(max-width: 991px){
      max-width: 100%;
      padding: 20px;
    }
  }

  .title-row {
    margin-bottom: 40px;
  }

  .title-col {
    padding: 0;
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .change-view {
    display: block;
    @media(max-width: 991px){
      display: none;
    }
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
    @media(max-width: 991px){
      margin-top: 120px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: #313132;
    margin-bottom: 24px;
    @media(max-width: 991px){
      margin-bottom: 15px;
    }
  }

  .column-cl {
    margin-bottom: 8%;

    .container {
      max-width: 100%;
    }
  }

  .client-single {
    &__inner {
      padding-top: 74.07%;
      border: 0 solid #707070;
      cursor: pointer;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        object-fit: cover;
        width: 92%;
      }

      .background {
        //background-color: rgba(40, 44, 52, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .middle-data {
        //border: 1px solid #9c9434;
        position: absolute;
        top: 20px;
        left: 20px;
        bottom: 20px;
        right: 20px;

        .middle-data-inner {
          img {
            object-fit: cover;
            width: 50%;
            height: 30%;
          }

          .brand-name {
            color: #fff;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 400;
            text-align: center;
            position: absolute;
            bottom: 30px;
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 1650px) {
    .client-single__inner .middle-data .middle-data-inner .brand-name {
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      bottom: 0;
    }
  }

  @media (max-width: 1024px) {
    .client-single__inner .middle-data .middle-data-inner .brand-name {
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      bottom: 0;
    }
  }

  @media (max-width: 991px) {
    .client-single__inner .middle-data .middle-data-inner .brand-name {
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      bottom: 20px;
    }
  }

  @media (max-width: 767px) {
    .client-single__inner .middle-data .middle-data-inner .brand-name {
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      bottom: 0px;
    }

    .column-cl {
      margin-bottom: 70px;
    }
  }

  @media (max-width: 575px) {
    .client-single__inner .middle-data .middle-data-inner .brand-name {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      bottom: 0px;
    }

    .column-cl {
      margin-bottom: 20%;
    }
  }
`;

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
  };
}

export default connect(mapStateToProps)(AllBrand);
